import { render, staticRenderFns } from "./modal-agent.vue?vue&type=template&id=4d77b1ac&scoped=true"
import script from "./modal-agent.vue?vue&type=script&lang=js"
export * from "./modal-agent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d77b1ac",
  null
  
)

export default component.exports