<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    @before-open="beforeOpen"
    :name="modalName"
  >
    <div class="modal--wrapper">
      <div class="modal--form-container">
        <form @submit.prevent="submit">
          <div
            class="form--group row"
            :class="{ 'has-error': validation.hasError('selectedProject') }"
          >
            <label class="col-12 col-lg-4">Pilih Proyek</label>
            <div class="col-12 col-lg-8">
              <multiselect
                name="selectedProject"
                id="selectedProjectField"
                v-model="selectedProject"
                :internal-search="false"
                :options="projects"
                :searchable="true"
                :close-on-select="true"
                track-by="uuid"
                label="project_name"
                :loading="projectLoading"
                @search-change="debounceGetProjects"
              />
              <span class="val-error">{{ validation.firstError('selectedProject') }}</span>
            </div>
          </div>
          <div
            class="form--group row"
            :class="{ 'has-error': validation.hasError('selectedAgent') }"
          >
            <label class="col-12 col-lg-4">Pilih Agen</label>
            <div class="col-12 col-lg-8">
              <multiselect
                name="selectedAgent"
                id="selectedAgentField"
                v-model="selectedAgent"
                :internal-search="false"
                :options="agents"
                :searchable="true"
                :close-on-select="true"
                track-by="id"
                label="name"
                :loading="agentLoading"
                @search-change="debounceGetAgents"
              />
              <span class="val-error">{{ validation.firstError('selectedAgent') }}</span>
            </div>
          </div>
          <hr class="my-4" />
          <div class="form--group row">
            <label class="col-12 col-lg-4">Nama Agen</label>
            <div class="col-12 col-lg-8">
              <v-text-field
                type="text"
                outlined
                hide-details
                :value="selectedAgent != null ? selectedAgent.name : ''"
                readonly
              />
            </div>
          </div>
          <div class="form--group row">
            <label class="col-12 col-lg-4">Nomor Telepon Agen</label>
            <div class="col-12 col-lg-8">
              <v-text-field
                type="text"
                outlined
                hide-details
                :value="selectedAgent != null ? selectedAgent.phone_number : ''"
                readonly
              />
            </div>
          </div>
          <div class="form--group row">
            <label class="col-12 col-lg-4">Email Agen</label>
            <div class="col-12 col-lg-8">
              <v-text-field
                type="text"
                outlined
                hide-details
                :value="selectedAgent != null ? selectedAgent.email : ''"
                readonly
              />
            </div>
          </div>
          <div class="d-flex align-center justify-center mt-4">
            <v-btn color="primary" class="bottom--button" type="submit">
              {{ $t('agent.btn.assign') }}
            </v-btn>
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
import LodashMixin from '@/mixins/lodash';

export default {
  name: 'modal-agent',
  mixins: [HelperMixin, LodashMixin],
  components: { Multiselect },
  data() {
    return {
      modalName: 'modal-agent',
      selectedProject: null,
      selectedAgent: null,
      commitString: null,
      dispatchString: null,
      dispatchAfterString: null,
      debounceGetProjects: null,
      debounceGetAgents: null,
    };
  },
  computed: {
    ...mapState({
      agents: (state) => state.agent.agents,
      agentLoading: (state) => state.agent.loading,
      projects: (state) => state.project.projects,
      projectLoading: (state) => state.project.loading,
    }),
  },
  validators: {
    selectedAgent(value) {
      return Validator.value(value).required(this.$i18n.t('errors.lead.assignedAgent.required'));
    },
  },
  created() {
    this.debounceGetProjects = this.debounce(this.getProjects, 700);
    this.debounceGetAgents = this.debounce(this.getAgents, 700);
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    selectedProject(val) {
      this.selectedAgent = null;
      this.getAgents(null);
    },
  },
  methods: {
    getProjects(query) {
      this.$store.dispatch('project/getData', {
        query: query,
      });
    },
    getAgents(query) {
      this.$store.dispatch('agent/getList', {
        q: query,
        project_uuid: this.selectedProject != null ? this.selectedProject.uuid : null,
        verified_otp_only: true,
      });
    },
    beforeOpen(event) {
      this.selectedProject = event.params.project;
      this.selectedAgent = event.params.agent;
      this.commitString = event.params.commitString;
      this.dispatchString = event.params.dispatchString;
      this.dispatchAfterString = event.params.dispatchAfterString;
      this.getProjects();
      this.getAgents();
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    async submit() {
      if (await this.$validate()) {
        this.$store.commit(this.commitString, this.selectedAgent);
        await this.$store.dispatch(this.dispatchString);
        if (this.dispatchAfterString) {
          await this.$store.dispatch(this.dispatchAfterString, this.$route.params.uuid);
        }
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('lead.assignAgent.successMsg'),
          'success',
        );
        this.close();
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>

<style scoped></style>
